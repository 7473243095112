<template>
  <div class="application">
    <div class="application-banner">
      <img :src="require('@imgs/vrClass/web/banner.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/vrClass/banner.png')" v-else />
      <div class="application-banner-title">
        <h3>VR实验室建设</h3>
        <!-- <span>VR LABORATORY CONSTRUCTION</span> -->
      </div>
    </div>
    <div class="application-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: 'vr-class' })">VR实验室建设</span> -> {{ appTabItem }}
    </div>
    <div class="application-content">
      <div class="application-tab">
        <div :class="{ 'application-tab-item': true, 'application-tab-item--active': appTabItem === 'VR实训室' }"
          @click="change('VR实训室')">VR实训室</div>
        <div :class="{ 'application-tab-item': true, 'application-tab-item--active': appTabItem === 'VR实训工具箱' }"
          @click="change('VR实训工具箱')">VR实训工具箱</div>
        <div :class="{ 'application-tab-item': true, 'application-tab-item--active': appTabItem === 'VR交互系统' }"
          @click="change('VR交互系统')">VR交互系统</div>
        <!-- <div :class="{'application-tab-item':true, 'application-tab-item--active': appTabItem === '新工科实验室项目'}" @click="change('新工科实验室项目')">新工科实验室项目</div> -->
      </div>
      <div style="height:20px;"></div>
      <div class="row" style="min-height: 212px;">
        <div class="application-item col-sm-3 col-12" v-for="(item) in applications" :key="item.id">
          <vr-class-item :info="item" :type="appTabItem"></vr-class-item>
        </div>
      </div>
      <page :total="total" @pageChange="getList"></page>
    </div>
    <div class="application-block">
      <div class="application-block-header">
        <div class="application-block-new-title">热门推荐</div>
        <div class="application-block-pages">
          <div class="application-block-pages-item" @click="$refs.swiper.pre()">
            <img :src="require('@imgs/searchback.png')" />
          </div>
          <div class="application-block-pages-item" @click="$refs.swiper.next()">
            <img :src="require('@imgs/listmore.png')" />
          </div>
        </div>
      </div>
      <swiper ref="swiper" :autoInit="false">
        <swiper-slide v-for="(item, index) in moreList" :key="index">
          <vr-class-item :info="item"></vr-class-item>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import swiper from '@components/swiper/swiper'
import swiperSlide from '@components/swiper/swiperSlide'
import vrClassItem from './vr-class-item'
import page from '@components/page/page'
export default {
  name: 'VRClass',
  components: {
    vrClassItem,
    page,
    swiper,
    swiperSlide
  },
  data() {
    return {
      appTabItem: 'VR实训室',
      applications: [],
      total: 0,
      moreList: []
    }
  },
  created() {
    this.getList(1)
    this.getXg()
  },
  methods: {
    change(item) {
      this.appTabItem = item
      this.getList(1)
    },
    toDetail(id) {
      this.$router.push({
        path: '/application-detail',
        query: {
          id
        }
      })
    },
    getList(pageNum) {
      let url = ''
      if (this.appTabItem === 'VR实训室') {
        url = 'room/list'
      }
      if (this.appTabItem === 'VR实训工具箱') {
        url = '/tool/list'
      }
      if (this.appTabItem === 'VR交互系统') {
        url = '/interactive/list'
      }
      if (this.appTabItem === '新工科实验室项目') {
        url = '/labproject/list'
      }
      if (pageNum === 1) {
        this.applications = []
      }
      this.$http({
        url,
        params: {
          pageNum,
          pageSize: 8
        }
      }).then(res => {
        this.applications = res.rows || []
        this.total = res.total
      })
    },
    getXg() {
      this.$http({
        url: `hot/list`
      }).then((res) => {
        this.moreList = res.rows || []
        this.$nextTick(() => {
          this.$refs.swiper.init({
            slidesPerView: this.$isMobile() ? 1 : 4,
            loop: false,
            autoplay: false
          })
        })
      })
    }
  }
}
</script>
<style scoped lang="scss">
.application {
  position: relative;

  &-item {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        width: 400px;
        display: inline-block;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 30px 30px 30px;
    background: #fff;
    margin-bottom: 40px;
  }

  &-header {
    margin-top: 60px;
    align-items: center;
  }

  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }

  &-tab {

    //display: flex;
    &-item {
      padding: 5px 16px;
      font-size: 16px;
      border: 1px solid #eee;
      color: #666666;
      cursor: pointer;
      margin-top: 10px;
      display: inline-block;
      font-family: Microsoft YaHei;
    }

    &-item--active {
      background: #2870b6;
      color: #fff;
      border: 0;
    }
  }

  &-block {
    max-width: 1200px;
    margin: 20px auto 40px auto;
    background: #fff;
    padding: 24px;

    &-title {
      padding-bottom: 12px;
      border-bottom: 1px solid #eee;
      justify-content: space-between;
      display: flex;
      margin-bottom: 20px;
    }

    &-new {
      &-title {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        position: relative;
        padding-left: 10px;
      }

      &-title::before {
        content: "";
        width: 4px;
        border-radius: 2px;
        height: 20px;
        left: 0px;
        position: absolute;
        background: #0862ca;
        display: block;
        top: 3px;
      }

      &-more {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        cursor: pointer;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
    }

    &-pages {
      display: flex;
      justify-content: flex-end;

      &-item {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-item:hover,
      &-item:active {
        background: #0862ca;
      }

      img {
        width: 12px;
      }
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}</style>